<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { fetchSettings } from "@/api/common";
import { required, numeric } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Processus Métier & Opérations",
    meta: [{ name: "description", content: appConfig.description }],
  },
  validations: {
    config: {
      requests: {
        mr_ref_prefix: { required },
        mo_ref_prefix: { required },
        vs_ref_prefix: { required },
        dr_ref_prefix: { required },
        tamr_ref_prefix: { required },
        mr_ref_format: { required },
        mo_ref_format: { required },
        vs_ref_format: { required },
        dr_ref_format: { required },
        tamr_ref_format: { required },
        mr_ref_position: { required, numeric },
        mo_ref_position: { required, numeric },
        vs_ref_position: { required, numeric },
        dr_ref_position: { required, numeric },
        tamr_ref_position: { required, numeric },
      },
      hosting: {
        booking_prefix: { required },
        booking_format: { required },
        booking_position: { required, numeric },
      },

      ods: {
        rest_ods_prefix: { required },
        maint_ods_prefix: { required },
        rest_ods_format: { required },
        maint_ods_format: { required },
        rest_ods_position: { required, numeric },
        maint_ods_position: { required, numeric },
      },

      invoicing: {
        invoice_prefix: { required },
        payment_prefix: { required },
        invoice_format: { required },
        payment_format: { required },
        invoice_position: { required, numeric },
        payment_position: { required, numeric },
      },
    },
  },
  components: { Layout, PageHeader },
  import: { fetchSettings },

  mounted() {
    this.populateCompanySettings();
  },

  methods: {
    populateCompanySettings() {
      fetchSettings()
        .then(({ data }) => {
          var configs = data.data;

          this.config.requests.mr_ref_prefix = configs.mr_ref_prefix;
          this.config.requests.mr_ref_position = configs.mr_ref_position;
          this.config.requests.mr_ref_format = configs.mr_ref_format;
          this.config.requests.mo_ref_prefix = configs.mo_ref_prefix;
          this.config.requests.mo_ref_position = configs.mo_ref_position;
          this.config.requests.mo_ref_format = configs.mo_ref_format;
          this.config.requests.vs_ref_prefix = configs.vs_ref_prefix;
          this.config.requests.vs_ref_position = configs.vs_ref_position;
          this.config.requests.vs_ref_format = configs.vs_ref_format;
          this.config.requests.dr_ref_prefix = configs.dr_ref_prefix;
          this.config.requests.dr_ref_position = configs.dr_ref_position;
          this.config.requests.dr_ref_format = configs.dr_ref_format;
          this.config.requests.tamr_ref_prefix = configs.tamr_ref_prefix;
          this.config.requests.tamr_ref_position = configs.tamr_ref_position;
          this.config.requests.tamr_ref_format = configs.tamr_ref_format;

          this.config.hosting.booking_prefix = configs.booking_prefix;
          this.config.hosting.booking_position = configs.booking_position;
          this.config.hosting.booking_format = configs.booking_format;

          this.config.ods.rest_ods_prefix = configs.rest_ods_prefix;
          this.config.ods.rest_ods_position = configs.rest_ods_position;
          this.config.ods.rest_ods_format = configs.rest_ods_format;
          this.config.ods.maint_ods_prefix = configs.maint_ods_prefix;
          this.config.ods.maint_ods_position = configs.maint_ods_position;
          this.config.ods.maint_ods_format = configs.maint_ods_format;

          this.config.invoicing.invoice_prefix = configs.invoice_prefix;
          this.config.invoicing.invoice_position = configs.invoice_position;
          this.config.invoicing.invoice_format = configs.invoice_format;
          this.config.invoicing.payment_prefix = configs.payment_prefix;
          this.config.invoicing.payment_position = configs.payment_position;
          this.config.invoicing.payment_format = configs.payment_format;

        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {});
    },
    submitRequestsConfig(){
      this.submitted = true;
      this.$v.config.requests.$touch();
      if (!this.$v.config.requests.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", this.config.requests)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.populateCompanySettings();
                break;
  
              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    submitHostingConfig(){
      this.submitted = true;
      this.$v.config.hosting.$touch();
      if (!this.$v.config.hosting.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", this.config.hosting)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.populateCompanySettings();
                break;
  
              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    submitOdsConfig(){
      this.submitted = true;
      this.$v.config.ods.$touch();
      if (!this.$v.config.ods.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", this.config.ods)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.populateCompanySettings();
                break;
  
              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    submitInvoicingConfig(){
      this.submitted = true;
      this.$v.config.invoicing.$touch();
      if (!this.$v.config.invoicing.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", this.config.invoicing)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.submitted = false;
                this.$toast.success(res.data.original.msg);
                this.populateCompanySettings();
                break;
  
              case 500:
                this.submitted = false;
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.submitted = false;
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    formSubmit() {
      
    },
  },
  data() {
    return {
      title: "Processus Métier & Opérations",
      submitted: false,
      showHelpModal: true,
      config: {
        requests: {
          mr_ref_prefix: "",
          mr_ref_position: "",
          mr_ref_format: "",

          mo_ref_prefix: "",
          mo_ref_position: "",
          mo_ref_format: "",

          vs_ref_prefix: "",
          vs_ref_position: "",
          vs_ref_format: "",

          dr_ref_prefix: "",
          dr_ref_position: "",
          dr_ref_format: "",

          tamr_ref_prefix: "",
          tamr_ref_position: "",
          tamr_ref_format: "",
        },

        // hosting
        hosting: {
          booking_prefix: "",
          booking_position: "",
          booking_format: "",
        },
        //ODS
        ods: {
          // REST
          rest_ods_prefix: "",
          rest_ods_position: "",
          rest_ods_format: "",
          // ODS MAIN
          maint_ods_prefix: "",
          maint_ods_position: "",
          maint_ods_format: "",
        },

        invoicing: {
          invoice_prefix: "",
          invoice_position: "",
          invoice_format: "",

          payment_prefix: "",
          payment_position: "",
          payment_format: "",
        },
      },
      items: [
        {
          text: "Paramètres",
          to: {name: 'base.settings.index'},
        },
        {
          text: "Processus Métier & Opérations",
          active: false,
        },
      ],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Modal @close="showHelpModal = false" v-model="showHelpModal" title="Help">
      <div class="row">
        <div class="col-lg-12">
          <b-alert show variant="info">
            <i class="mdi mdi-alert-circle-outline mr-2"></i>Comment paraméter le format de référence ?
          </b-alert>
         <p class="card-text">Le format de référence doit respecter la syntaxe: <b>{PREFIX}/{YEAR}/{SN}</b>.</p> <br>
         <ol>
           <li><p>Exemple: <b>BS/2021/00001</b></p></li>
           <li><p><b>{PREFIX}</b>: Le préfix de document</p></li>
           <li><p><b>{YEAR}</b>: L'année courrante (Générée automatiquement par le système)</p></li>
           <li><p><b>{SN}</b>: Le numéro séquentiel de document (inséré automatiquement par le système)</p></li>
           <li><p><b>/</b>: Le séparateur entre les valeurs précédentes (vous pouvez utiliser un séparateur a votre choix)</p></li>
         </ol>
         
         
        </div>
        
    </div>
    </Modal>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            
            <b-tabs
              pills
              vertical
              nav-class="p-0"
              nav-wrapper-class="col-sm-2"
              content-class="pt-0 px-2 text-muted"
            >
              <b-tab title="Requêtes" active title-item-class="mb-2">
                <!-- Requêtes Maintenance -->
                <form @submit.prevent="submitRequestsConfig">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Requêtes Maintenance</h4>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Préfix</label>
                            <input
                              type="text"
                              v-model="config.requests.mr_ref_prefix"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.mr_ref_prefix.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.mr_ref_prefix.$error" class="invalid-feedback">
                                <span v-if="!$v.config.requests.mr_ref_prefix.required">Champ Obligatoire.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Positions</label>
                            <input
                              type="text"
                              v-model="config.requests.mr_ref_position"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.mr_ref_position.$error,
                                }"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.mr_ref_position.$error" class="invalid-feedback">
                                <span v-if="!$v.config.requests.mr_ref_position.required">Champ Obligatoire.</span>
                                <span v-if="!$v.config.requests.mr_ref_position.numeric">Champ invalide.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Format
                              <a href="javascript:void(0)" class="ml-2">
                                <i @click="showHelpModal = true" class="fas fa-info-circle float-right text-primary" style="font-size:18px;"></i>
                              </a>
                            </label>
                            <input
                              type="text"
                              v-model="config.requests.mr_ref_format"
                              class="form-control"
                              name=""
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.mr_ref_format.$error,
                                }"
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.mr_ref_format.$error" class="invalid-feedback">
                                <span v-if="!$v.config.requests.mr_ref_format.required">Champ Obligatoire.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Ordre de maintenance Maintenance -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Ordre de Maintenance</h4>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Préfix</label>
                            <input
                              type="text"
                              v-model="config.requests.mo_ref_prefix"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.mo_ref_prefix.$error,
                                }"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.mo_ref_prefix.$error" class="invalid-feedback">
                                <span v-if="!$v.config.requests.mo_ref_prefix.required">Champ Obligatoire.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Positions</label>
                            <input
                              type="text"
                              v-model="config.requests.mo_ref_position"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.mo_ref_position.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.mo_ref_position.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.requests.mo_ref_position.required">Champ Obligatoire.</span>
                                <span v-if="!$v.config.requests.mo_ref_position.numeric"
                                >Champ invalide.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Format
                              <a href="javascript:void(0)" class="ml-2">
                                <i @click="showHelpModal = true" class="fas fa-info-circle float-right text-primary" style="font-size:18px;"></i>
                              </a>
                            </label>
                            <input
                              type="text"
                              v-model="config.requests.mo_ref_format"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.mo_ref_format.$error,
                                }"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.mo_ref_format.$error" class="invalid-feedback">
                              <span v-if="!$v.config.requests.mo_ref_format.required">Champ Obligatoire.</span>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Prise en charge -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Requêtes Prise en charge</h4>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Préfix</label>
                            <input
                              type="text"
                              v-model="config.requests.vs_ref_prefix"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.vs_ref_prefix.$error,
                                }"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.vs_ref_prefix.$error" class="invalid-feedback">
                              <span v-if="!$v.config.requests.vs_ref_prefix.required">Champ Obligatoire.</span>
                              <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Positions</label>
                            <input
                              type="text"
                              v-model="config.requests.vs_ref_position"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.vs_ref_position.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.vs_ref_position.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.requests.vs_ref_position.required">Champ Obligatoire.</span>
                                <span v-if="!$v.config.requests.vs_ref_position.numeric"
                                >Champ invalide.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Format
                              <a href="javascript:void(0)" class="ml-2">
                                <i @click="showHelpModal = true" class="fas fa-info-circle float-right text-primary" style="font-size:18px;"></i>
                              </a>
                            </label>
                            <input
                              type="text"
                              v-model="config.requests.vs_ref_format"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.vs_ref_format.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.vs_ref_format.$error" class="invalid-feedback">
                              <span v-if="!$v.config.requests.vs_ref_format.required">Champ Obligatoire.</span>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Demande de dotations -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">
                        Requêtes Demande de dotations
                      </h4>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Préfix</label>
                            <input
                              type="text"
                              v-model="config.requests.dr_ref_prefix"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.dr_ref_prefix.$error,
                                }"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.dr_ref_prefix.$error" class="invalid-feedback">
                              <span v-if="!$v.config.requests.dr_ref_prefix.required">Champ Obligatoire.</span>
                              <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Positions</label>
                            <input
                              type="text"
                              v-model="config.requests.dr_ref_position"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.dr_ref_position.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.dr_ref_position.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.requests.dr_ref_position.required">Champ Obligatoire.</span>
                                <span v-if="!$v.config.requests.dr_ref_position.numeric"
                                >Champ invalide.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Format
                              <a href="javascript:void(0)" class="ml-2">
                                <i @click="showHelpModal = true" class="fas fa-info-circle float-right text-primary" style="font-size:18px;"></i>
                              </a>
                            </label>
                            <input
                              type="text"
                              v-model="config.requests.dr_ref_format"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.dr_ref_format.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.dr_ref_format.$error" class="invalid-feedback">
                              <span v-if="!$v.config.requests.dr_ref_format.required">Champ Obligatoire.</span>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- TAW MEAL -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">
                        Requêtes Demande de repas emporté
                      </h4>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Préfix</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="config.requests.tamr_ref_prefix"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.tamr_ref_prefix.$error,
                                }"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.tamr_ref_prefix.$error" class="invalid-feedback">
                              <span v-if="!$v.config.requests.tamr_ref_prefix.required">Champ Obligatoire.</span>
                              <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Positions</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="config.requests.tamr_ref_position"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.tamr_ref_position.$error,
                                }"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.tamr_ref_position.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.requests.tamr_ref_position.required">Champ Obligatoire.</span>
                                <span v-if="!$v.config.requests.tamr_ref_position.numeric"
                                >Champ invalide.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Format
                              <a href="javascript:void(0)" class="ml-2">
                                <i @click="showHelpModal = true" class="fas fa-info-circle float-right text-primary" style="font-size:18px;"></i>
                              </a>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="config.requests.tamr_ref_format"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.requests.tamr_ref_format.$error,
                                }"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.requests.tamr_ref_format.$error" class="invalid-feedback">
                              <span v-if="!$v.config.requests.tamr_ref_format.required">Champ Obligatoire.</span>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab title="Hébergement" title-item-class="mb-2">
                <form @submit.prevent="submitHostingConfig">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Réservations</h4>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Préfix</label>
                            <input
                              type="text"
                              v-model="config.hosting.booking_prefix"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.hosting.booking_prefix.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.hosting.booking_prefix.$error" class="invalid-feedback">
                                <span v-if="!$v.config.hosting.booking_prefix.required"
                                >Champ Obligatoire.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Positions</label>
                            <input
                              type="text"
                              v-model="config.hosting.booking_position"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.hosting.booking_position.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.hosting.booking_position.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.hosting.booking_position.required">Champ Obligatoire.</span>
                                <span v-if="!$v.config.hosting.booking_position.numeric">Champ invalide.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Format
                              <a href="javascript:void(0)" class="ml-2">
                                <i @click="showHelpModal = true" class="fas fa-info-circle float-right text-primary" style="font-size:18px;"></i>
                              </a>
                            </label>
                            <input
                              type="text"
                              v-model="config.hosting.booking_format"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.hosting.booking_format.$error,
                                }"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.hosting.booking_format.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.hosting.booking_format.required">Champ Obligatoire.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Ordre de maintenance Maintenance -->
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab title="ODS">
                <b-card-text>
                  <form @submit.prevent="submitOdsConfig">
                    <div class="row">
                      <div class="col-lg-12 ">
                        <h4 class="card-title mb-4">ODS Restauration</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Préfix</label>
                              <input
                                type="text"
                                v-model="config.ods.rest_ods_prefix"
                                :class="{
                                'is-invalid':
                                    submitted && $v.config.ods.rest_ods_prefix.$error,
                                }"                                
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div v-if="submitted && $v.config.ods.rest_ods_prefix.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.ods.rest_ods_prefix.required">Champ Obligatoire.</span>
                                <br />
                            </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Positions</label>
                              <input
                                type="text"
                                v-model="config.ods.rest_ods_position"
                                :class="{
                                'is-invalid':
                                    submitted && $v.config.ods.rest_ods_position.$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div v-if="submitted && $v.config.ods.rest_ods_position.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.ods.rest_ods_position.required">Champ Obligatoire.</span>
                                <span v-if="!$v.config.ods.rest_ods_position.numeric">Champ invalide.</span>
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">
                              Format
                              <a href="javascript:void(0)" class="ml-2">
                                <i @click="showHelpModal = true" class="fas fa-info-circle float-right text-primary" style="font-size:18px;"></i>
                              </a>
                            </label>
                              <input
                                type="text"
                                v-model="config.ods.rest_ods_format"
                                class="form-control"
                                :class="{
                                'is-invalid':
                                    submitted && $v.config.ods.rest_ods_format.$error,
                                }"
                                name=""
                                id=""
                              />
                              <div v-if="submitted && $v.config.ods.rest_ods_format.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.ods.rest_ods_format.required">Champ Obligatoire.</span>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-lg-12 ">
                        <h4 class="card-title mb-4">ODS Maintenance</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Préfix</label>
                              <input
                                type="text"
                                v-model="config.ods.maint_ods_prefix"
                                class="form-control"
                                :class="{
                                'is-invalid':
                                    submitted && $v.config.ods.maint_ods_prefix.$error,
                                }"
                                name=""
                                id=""
                              />
                              <div v-if="submitted && $v.config.ods.maint_ods_prefix.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.ods.maint_ods_prefix.required">Champ Obligatoire.</span>
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">Positions</label>
                              <input
                                type="text"
                                v-model="config.ods.maint_ods_position"
                                :class="{
                                'is-invalid':
                                    submitted && $v.config.ods.maint_ods_position.$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div v-if="submitted && $v.config.ods.maint_ods_position.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.ods.maint_ods_position.required">Champ Obligatoire.</span>
                                <span v-if="!$v.config.ods.maint_ods_position.numeric">Champ invalide.</span>
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-grou">
                              <label for="">
                              Format
                              <a href="javascript:void(0)" class="ml-2">
                                <i @click="showHelpModal = true" class="fas fa-info-circle float-right text-primary" style="font-size:18px;"></i>
                              </a>
                            </label>
                              <input
                                type="text"
                                v-model="config.ods.maint_ods_format"
                                :class="{
                                'is-invalid':
                                    submitted && $v.config.ods.maint_ods_format.$error,
                                }"
                                class="form-control"
                                name=""
                                id=""
                              />
                              <div v-if="submitted && $v.config.ods.maint_ods_format.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.ods.maint_ods_format.required">Champ Obligatoire.</span>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <!-- Ordre de maintenance Maintenance -->
                    <div class="row">
                      <div class="col-md-12">
                        <button class="btn btn-primary float-right">
                          <i class="fas fa-save"></i> Enregistrer
                        </button>
                      </div>
                    </div>
                  </form>
                </b-card-text>
              </b-tab>
              <b-tab title="Facturation">
                <form @submit.prevent="submitInvoicingConfig">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Facturation</h4>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Préfix</label>
                            <input
                              type="text"
                              v-model="config.invoicing.invoice_prefix"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.invoicing.invoice_prefix.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.invoicing.invoice_prefix.$error" class="invalid-feedback">
                              <span v-if="!$v.config.invoicing.invoice_prefix.required">Champ Obligatoire.</span>
                              <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Positions</label>
                            <input
                              type="text"
                              v-model="config.invoicing.invoice_position"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.invoicing.invoice_position.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.invoicing.invoice_position.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.invoicing.invoice_position.required">Champ Obligatoire.</span>
                                <span v-if="!$v.config.invoicing.invoice_position.numeric">Champ invalide.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Format
                              <a href="javascript:void(0)" class="ml-2">
                                <i @click="showHelpModal = true" class="fas fa-info-circle float-right text-primary" style="font-size:18px;"></i>
                              </a>
                            </label>
                            <input
                              type="text"
                              v-model="config.invoicing.invoice_format"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.invoicing.invoice_format.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.invoicing.invoice_format.$error"
                              class="invalid-feedback">
                              <span v-if="!$v.config.invoicing.invoice_format.required">Champ Obligatoire.</span>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Paiements</h4>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Préfix</label>
                            <input
                              type="text"
                              v-model="config.invoicing.payment_prefix"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.invoicing.payment_prefix.$error,
                                }"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.invoicing.payment_prefix.$error"
                              class="invalid-feedback">
                              <span v-if="!$v.config.invoicing.payment_prefix.required">Champ Obligatoire.</span>
                              <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Positions</label>
                            <input
                              type="text"
                              v-model="config.invoicing.payment_position"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.invoicing.payment_position.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.invoicing.payment_position.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.invoicing.payment_position.required">Champ Obligatoire.</span>
                                <span v-if="!$v.config.invoicing.payment_position.numeric">Champ invalide.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Format
                              <a href="javascript:void(0)" class="ml-2">
                                <i @click="showHelpModal = true" class="fas fa-info-circle float-right text-primary" style="font-size:18px;"></i>
                              </a>
                            </label>
                            <input
                              type="text"
                              v-model="config.invoicing.payment_format"
                              :class="{
                                'is-invalid':
                                    submitted && $v.config.invoicing.payment_format.$error,
                                }"
                              class="form-control"
                              name=""
                              id=""
                            />
                            <div v-if="submitted && $v.config.invoicing.payment_format.$error"
                                class="invalid-feedback">
                                <span v-if="!$v.config.invoicing.payment_format.required">Champ Obligatoire.</span>
                                <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Ordre de maintenance Maintenance -->
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
